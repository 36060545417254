































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Unlicensed extends Vue {
    @Prop()
    orderForm!: string;

    private dialog = false;

    private async openPublicFile(fileName: string) {
        this.$router.push({ name: 'open', params: { data: 'api/publicfiles/' + fileName } });
    }
}
