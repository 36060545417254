import routes from '@/routes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const router = async () => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const { default: VueRouter } = await import(/* webpackChunkName: "vue-router" */ 'vue-router');
    Vue.use(VueRouter);

    return new VueRouter({
        routes
    });
};

export default router;
