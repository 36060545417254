import { DownloadsApiClient } from '@/repositories';

export interface IContainer {
    downloadsApiClient: DownloadsApiClient;
}

export default class Container implements IContainer {
    get downloadsApiClient(): DownloadsApiClient {
        return new DownloadsApiClient();
    }
}
