import { ModuleTree } from 'vuex';
import IRootState from './rootState';
import downloads from './downloadsStore';

const stores = { downloads } as ModuleTree<IRootState>;

export * from './rootState';
export * from './downloadsStore';

export { stores, downloads as downloadsStore };

export default stores;
