












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Unlicensed from './unlicensed.vue';
import { DownloadableViewModel, FileResponse } from '../repositories';
import { saveAs } from 'file-saver';

const downloads = namespace('downloads');

@Component({
    components: {
        Unlicensed
    }
})
export default class DownloadableItem extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @downloads.Mutation('setLoading') setLoading!: (value: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @downloads.Action('get') getDownload!: (title: string) => Promise<FileResponse>;

    @Prop()
    downloadable!: DownloadableViewModel;

    private downloading = false;

    private get isDownloading() {
        return this.downloading;
    }

    private async download(title: string) {
        this.downloading = true;
        const fileResponse = await this.getDownload(title);
        this.downloading = false;
        saveAs(fileResponse.data, fileResponse.fileName);
    }

    private async open(title: string) {
        this.setLoading(true);
        const fileResponse = await this.getDownload(title);
        const to = URL.createObjectURL(fileResponse.data);
        this.$router.push({ name: 'open', params: { data: to } });
    }

    private thumbnailUrl(): string {
        if (this.downloadable.thumbnail === '') {
            return 'default_thumbnail.svg';
        } else {
            return 'api/publicfiles/' + this.downloadable.thumbnail;
        }
    }
}
