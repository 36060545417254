import { ActionContext, Module } from 'vuex';
import { DownloadableViewModel, FileResponse } from '@/repositories';
import IRootState from '@/stores/rootState';

interface IDownloadsState {
    loading: boolean;
    downloadables: DownloadableViewModel[];
}

class DownloadsStore implements Module<IDownloadsState, IRootState> {
    namespaced = true;

    state: IDownloadsState = {
        loading: true,
        downloadables: []
    };

    // getters
    getters = {};

    // mutations
    mutations = {
        setLoading(s: IDownloadsState, value: boolean): void {
            s.loading = value;
        },
        load(s: IDownloadsState, value: DownloadableViewModel[]): void {
            s.downloadables = value;
        }
    };

    // actions
    actions = {
        async get({ rootState }: ActionContext<IDownloadsState, IRootState>, fileName: string): Promise<FileResponse> {
            return await rootState.container.downloadsApiClient.get(fileName);
        },

        async load({ commit, rootState }: ActionContext<IDownloadsState, IRootState>): Promise<void> {
            try {
                const downloadables = await rootState.container.downloadsApiClient.list();
                commit('load', downloadables);
            } catch (e) {
                console.error('Error getting list of downloadables:' + e);
            }
        }
    };
}

export { IDownloadsState, DownloadsStore };

export default new DownloadsStore();
