













import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DownloadableItem from './downloadableItem.vue';
import LoadingComponent from './loading.vue';
import { DownloadableViewModel } from '@/repositories';

const downloads = namespace('downloads');

@Component({ components: { DownloadableItem, LoadingComponent } })
export default class DownloadsComponent extends Vue {
    @downloads.State('loading') loading!: boolean;
    @downloads.State('downloadables') downloadables!: DownloadableViewModel[];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @downloads.Mutation('setLoading') setLoading!: (value: boolean) => void;
    @downloads.Action('load') load!: () => Promise<void>;

    async created(): Promise<void> {
        await this.load();
        this.setLoading(false);
    }
}
