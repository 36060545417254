import DownloadsComponent from '@/components/downloads.vue';
import { Route, RouteConfig } from 'vue-router';

export const routes: RouteConfig[] = [
    { path: '/', name: 'root', component: DownloadsComponent },
    { path: '/blog', name: 'blog', beforeEnter: (): string => (location.href = 'http://www.wattclarity.com.au') },
    { path: '/open', name: 'open', beforeEnter: (to: Route): string => (location.href = to.params.data) }
];

export default routes;
